import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { FullScreenCardGridStyles } from '../../src/layout/grid/Grid'
import { media } from '../../src/utils/style-utils'
import ImageCard from '../../src/components/Card/ImageCard'
import { CardAspectStyles, CardStyles } from '../../src/components/Card'
import { TwoBoxSquareSliceSlice } from '../../prismicio-types'

import TwoBoxCard from './TwoBoxCard'

const CARD_VARIANTS = {
	hover: {
		scale: 0.99,
		transition: { duration: 0.1, ease: 'easeInOut' },
	},
}

const TwoBoxSquareSlice = ({ slice }: { slice: TwoBoxSquareSliceSlice }) => {
	const [isHovered, setHovered] = useState(false)

	return (
		<Grid>
			<HeroImageContainer
				whileHover='hover'
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				variants={CARD_VARIANTS}
			>
				<ImageCard image={slice.primary.hero_image} />
			</HeroImageContainer>
			<CardsContainer>
				<TwoBoxCard
					image={slice.primary.card_1_image}
					title={slice.primary.card_1_title ?? ''}
					description={slice.primary.card_1_description ?? ''}
					link={slice.primary.card_1_link}
					theme='light'
				/>
				<TwoBoxCard
					image={slice.primary.card_2_image}
					title={slice.primary.card_2_title ?? ''}
					description={slice.primary.card_2_description ?? ''}
					link={slice.primary.card_2_link}
					theme='dark'
				/>
			</CardsContainer>
		</Grid>
	)
}

export default TwoBoxSquareSlice

const Grid = styled.div`
	${FullScreenCardGridStyles}

	overflow: visible;

	${media.desktopLg`
		gap: 25px;	
	`}

	${media.desktopMd`
		gap: 20px;	
	`}
`

const HeroImageContainer = styled(motion.div)`
	grid-column: 1 / 6;
	transition: var(--animation-transition);

	width: 100%;
	height: 930px;
	position: relative;
	${CardStyles}
	${CardAspectStyles}

	${media.desktopHuge`
		max-height: 1350px;
	`}

	${media.desktopXl`
		max-height: 1150px;
	`}

	${media.desktopMd`
		max-height: 800px;
	`};

	${media.tablet`
		aspect-ratio: 16/13;
		max-height: unset;

		grid-column: 1/-1;
	`}

	${media.phone`
		max-height: 88vh;
	`}
`

const CardsContainer = styled.div`
	grid-column: 6 / 11;

	width: 100%;
	height: 930px;
	position: relative;

	${CardAspectStyles}

	${media.desktopHuge`
		max-height: 1350px;
	`}

	${media.desktopXl`
		max-height: 1150px;
	`}

	${media.desktopMd`
		max-height: 800px;
	`};

	${media.tablet`
		grid-column: 1/-1;

		padding: 0;

		justify-content:center;
		gap: 20px;

		height: 1200px;
	`}

	${media.phone`
		margin-top: 0px;
		height: 100%;
	`}

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	overflow: visible;

	gap: 30px;
`
