import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { ImageField, LinkField } from '@prismicio/client'

import DotArrow from '../../src/components/LinkCard/DotArrow'
import { media } from '../../src/utils/style-utils'
import { getLinkUrl } from '../../src/utils/ts-helpers'

const CARD_VARIANTS = {
	hover: {
		scale: 1.02,
		transition: { duration: 0.1, ease: 'easeInOut' },
	},
	tap: {
		scale: 0.97,
		transition: { duration: 0.1, ease: 'easeInOut' },
	},
}

interface ITwoBoxCard {
	theme: 'light' | 'dark'
	image: ImageField
	title: string
	description: string
	link: LinkField
}

function TwoBoxCard(props: ITwoBoxCard) {
	const [isHovered, setHovered] = useState(false)

	return (
		<LinkWrapper href={getLinkUrl(props.link)}>
			<Card
				whileTap='tap'
				whileHover='hover'
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				variants={CARD_VARIANTS}
				style={{ backgroundColor: props.theme === 'light' ? 'var(--baby-blue)' : 'var(--deep-blue)' }}
			>
				<ItemImage
					src={props.image.url ?? ''}
					alt={props.image.alt ?? ''}
					width={150}
					height={200}
				/>

				<TextWrapper style={{ color: props.theme === 'light' ? 'var(--deep-blue)' : 'var(--neon)' }}>
					<ItemTitle>{props.title}</ItemTitle>
					<ItemDescription>{props.description}</ItemDescription>
				</TextWrapper>

				<IconWrapper>
					<DotArrow
						backgroundColor={props.theme === 'light' ? 'var(--deep-blue)' : 'var(--neon)'}
						isHovered={isHovered}
					/>
				</IconWrapper>
			</Card>
		</LinkWrapper>
	)
}

export default TwoBoxCard

const LinkWrapper = styled(Link)`
	display: flex;
	flex: 1 0 calc(33.33% - 24px);
	min-width: calc(33.33% - 24px);

	${media.phone`
        height: 300px;
        flex: none;
        min-width: none;       
	`}
`

const Card = styled(motion.div)`
	border-radius: 56px;

	width: 100%;

	display: flex;
	align-items: center;
	gap: 30px;

	padding-block: var(--spacer-base);
	padding-inline: var(--spacer-lg) var(--spacer-xs);

	cursor: pointer;

	&:hover {
		filter: brightness(105%);
		box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.15);
	}

	&:focus-visible {
		outline: 3px solid var(--orange);
		outline-offset: 1px;
	}

	transition: var(--animation-transition);

	${media.desktopSm`
		padding-left: var(--spacer-md);
	`};

	${media.phone`
        height: 300px;
		padding-left: var(--spacer-md);
	`}
`

const ItemImage = styled(Image)`
	flex: 1;
	object-fit: contain;

	${media.phone`
        height: 120px;
        width: 120px;
	`}
`

const TextWrapper = styled.div`
	flex: 3;

	display: flex;
	flex-direction: column;
	gap: 0px;

	font-family: var(--font-atyp-display);
`

const ItemTitle = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: clamp(1.5rem, 3vw + 1rem, 2.5rem);
	line-height: 3.5rem;
`

const ItemDescription = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
	line-height: 140%;
`

const IconWrapper = styled.div`
	flex: 0;

	height: 32px;
	width: 32px;

	${media.phone`		
		display: none;
	`}
`
