import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { KvikaSVGProps } from '../icons/types'

const CLOSED_VARIANT = {
	cx: 25,
	cy: 16,
}
const OPEN_VARIANT = {}

const TRANSITION = {
	opacity: {
		duration: 0.2,
	},
}

const ANIMATION_CONFIG = {
	// transition: TRANSITION,
	// initial: false,
}

const DotArrow = ({ backgroundColor, isHovered }) => {
	return (
		<AnimatePresence>
			<motion.svg
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				animate={
					isHovered ? { opacity: 1, transition: { duration: 0 } } : { opacity: 0, transition: { duration: 0.2 } }
				}
			>
				<motion.circle
					cx='19'
					cy='10'
					r='3'
					animate={isHovered ? { ...OPEN_VARIANT, cx: 19, cy: 10 } : CLOSED_VARIANT}
					fill={backgroundColor}
					{...ANIMATION_CONFIG}
				/>
				<motion.circle
					cx='13'
					cy='4'
					r='3'
					fill={backgroundColor}
					animate={isHovered ? { ...OPEN_VARIANT, cx: 13, cy: 4 } : CLOSED_VARIANT}
					{...ANIMATION_CONFIG}
				/>
				<motion.circle
					cx='25'
					cy='16'
					r='3'
					fill={backgroundColor}
					animate={isHovered ? { ...OPEN_VARIANT } : CLOSED_VARIANT}
					{...ANIMATION_CONFIG}
				/>
				<motion.circle
					cx='19'
					cy='22'
					r='3'
					fill={backgroundColor}
					animate={isHovered ? { ...OPEN_VARIANT, cx: 19, cy: 22 } : CLOSED_VARIANT}
					{...ANIMATION_CONFIG}
				/>
				<motion.circle
					cx='13'
					cy='28'
					r='3'
					fill={backgroundColor}
					animate={isHovered ? { ...OPEN_VARIANT, cx: 13, cy: 28 } : CLOSED_VARIANT}
					{...ANIMATION_CONFIG}
				/>
			</motion.svg>
		</AnimatePresence>
	)
}

export default DotArrow
